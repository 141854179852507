import React, { FunctionComponent } from 'react';
import parse, { domToReact } from 'html-react-parser';
import Gist from 'react-gist';
import get from 'lodash/get';
import styles from './blog-article-content.module.scss';
import { CopyBlock, atomOneLight as theme } from 'react-code-blocks';
import RichText from '../../../rich-text/rich-text';
import { CTAButton } from '../../../cta-button/cta-button';
import { Models } from '../../../../models';
import { PageSection } from '../../../page-section/page-section';
import { KENTICO_TYPES } from '../../../../const/common/kentico';

interface IProps {
  content: string;
  className?: string;
  components?: object;
}

const P = ({ children }: { children: string }): JSX.Element => (
  <p className={styles.paragraph}> {children} </p>
);

const H1 = ({ children }: { children: string }): JSX.Element => (
  <h1 className={styles.headerExtraLarge}> {children} </h1>
);

const H2 = ({ children }: { children: string }): JSX.Element => (
  <h2 className={styles.headerLarge}> {children} </h2>
);

const H3 = ({ children }: { children: string }): JSX.Element => (
  <h3 className={styles.headerRegular}> {children} </h3>
);

const EM = ({ children }: { children: string }): JSX.Element => (
  <i>{children[1] || children}</i>
);

const STRONG = ({ children }: { children: string }): JSX.Element => (
  <strong>{children[1] || children}</strong>
);

const UL = ({ children }: { children: string }): JSX.Element => (
  <div className={styles.listWrapper}>
    <ul className={styles.unorderedList}>{children}</ul>
  </div>
);

const LI = ({ children }: { children: string }): JSX.Element => (
  <li className={styles.listItem}>
    <span>{children}</span>
  </li>
);

const A = (props): JSX.Element => {
  return (
    <a
      className={styles.link}
      target={'_blank'}
      rel="noopener noreferrer"
      href={props.attribs.href ? props.attribs.href : '#'}
    >
      {props.children[1] || props.children}
    </a>
  );
};

const PRE = (props): JSX.Element | null => {
  const children = props.children[1] || props.children;
  const codeLang = props.attribs.class && props.attribs.class.slice(9);

  return (
    <pre>
      {props.children[1].type === 'code'
        ? CODE(children.props.children, codeLang)
        : props.children}
    </pre>
  );
};

const CODE = (code: string, codeLang: string): JSX.Element | null => {
  const showLineNumbers = code.toString().split('\n').length > 1;
  codeLang = codeLang === 'dotnet' ? 'java' : codeLang;
  codeLang = codeLang === 'markup' ? 'swift' : codeLang;

  return (
    <CopyBlock
      language={codeLang || 'jsx'}
      text={code}
      showLineNumbers={showLineNumbers}
      theme={theme}
      wrapLines={true}
      codeBlock
    />
  );
};

const SCRIPT = (props): JSX.Element | null => {
  try {
    const getSrc = props.attribs.src;
    const srcParts = getSrc.split('/');
    const idParts = srcParts[srcParts.length - 1].split('.');
    const id = idParts[0];
    return id && id.length ? (
      <Gist id={id} />
    ) : (
      <a href={props.attribs.src}>Script</a>
    );
  } catch (err) {
    return <a href={props.attribs.src}>Script</a>;
  }
};

const OBJECT = props => {
  const codeName = get(props, 'attribs.data-codename');
  const link = get(props, 'links', []).find(
    link => link.system.codename === codeName
  );

  switch (link.system.type) {
    case KENTICO_TYPES.CTA_BUTTON: {
      const modeledData = Models.CtaButtonModel.create(link as ICTAButtonData);
      return <CTAButton {...modeledData} />;
    }
    case KENTICO_TYPES.PAGE_SECTION: {
      const modeledData = Models.PageSectionModel.create(link as IPageSection);
      return <PageSection {...modeledData} />;
    }
    default:
      return null;
  }
};

const componentsMapping = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  li: LI,
  ul: UL,
  em: EM,
  pre: PRE,
  strong: STRONG,
  script: SCRIPT,
  object: OBJECT
};

const options = (props = {}) => ({
  replace: (domNode): JSX.Element | undefined => {
    if (domNode.name in componentsMapping) {
      const T = componentsMapping[domNode.name];
      if (!T) {
        return;
      }

      return (
        <T attribs={domNode.attribs} links={props}>
          {' '}
          {domToReact(domNode.children, options())}{' '}
        </T>
      );
    }
    return;
  }
});

export const BlogArticleContent: FunctionComponent<IProps> = props => {
  return (
    <div className={styles.contentContainer}>
      <div className={props.className}>
        {parse(props.content, options(props.components))}
      </div>
    </div>
  );
};
