import React, { FunctionComponent } from 'react';
import styles from './blog-popular-articles.module.scss';
import {
  BlogPopularArticleItem,
  IBlogPopularArticleItemProps
} from './blog-popular-article-item/blog-popular-article-item';
import { useGlobalTranslations } from '../../../../hooks/use-global-translations';

export interface IBlogPopularArticlesProps {
  articles: IBlogPopularArticleItemProps[];
  className?: string;
}

const BlogPopularArticlesComponent: FunctionComponent<IBlogPopularArticlesProps> = props => {
  const { articles, className } = props;
  const { popular } = useGlobalTranslations();
  return (
    <div className={className ? className : ''}>
      <div className={styles.title}>{popular}:</div>
      <div className={styles.articlesWrapper}>
        {articles.map((article: IBlogPopularArticleItemProps, i: number) => (
          <BlogPopularArticleItem key={i} {...article} />
        ))}
      </div>
    </div>
  );
};

export const BlogPopularArticles = React.memo(BlogPopularArticlesComponent);
