import React, { FunctionComponent } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share';
import styles from './share-buttons.module.scss';
import { useGlobalTranslations } from '../../hooks/use-global-translations';

interface IShareButtonsProps {
  className?: string;
}
const url = typeof window !== 'undefined' ? window.location.href : '';
const iconColor = '#9ca1b7';

export const ShareButtons: FunctionComponent<IShareButtonsProps> = ({
  className
}) => {
  const { share } = useGlobalTranslations();

  return (
    <div className={className ? className : ''}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>{share}:</h4>
        <div className={styles.iconsWrapper}>
          <FacebookShareButton className={styles.shareButton} url={url}>
            <FacebookIcon
              className={`${styles.icon} ${styles.facebook}`}
              size={50}
              iconFillColor={iconColor}
              bgStyle={{ fill: 'transparent' }}
            />
          </FacebookShareButton>
          <LinkedinShareButton className={styles.shareButton} url={url}>
            <LinkedinIcon
              className={`${styles.icon} ${styles.linkedin}`}
              size={50}
              iconFillColor={iconColor}
              bgStyle={{ fill: 'transparent' }}
            />
          </LinkedinShareButton>
          <TwitterShareButton className={styles.shareButton} url={url}>
            <TwitterIcon
              className={`${styles.icon} ${styles.twitter}`}
              size={50}
              iconFillColor={iconColor}
              bgStyle={{ fill: 'transparent' }}
            />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};
