import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { useContext } from 'react';
import { AppStore } from '../../../store/app.context';
import { authorUrl } from '../../../utils/utils';
import { IEmployeeBoxProps } from '../../employee-box/employee-box';
import {
  properOrderForSocialMedia,
  SocialMediaIcon
} from '../../employee-box/social-media/social-media';
import { ExternalLink } from '../../external-link/external-link';
import { InternalLink } from '../../internal-link/internal-link';
import styles from './blog-author-section.module.scss';

export interface IBlogAuthorSectionProps {
  author: IEmployeeBoxProps;
  withSocialLinks?: boolean;
}

export const BlogAuthorSection: FunctionComponent<IBlogAuthorSectionProps> = ({
  author,
  withSocialLinks
}) => {
  const {
    state: {
      showPageState: { currentLanguage }
    }
  } = useContext(AppStore);

  const navigateToAuthor = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const authorLink = authorUrl(author.slug);
    currentLanguage !== 'en'
      ? navigate(`/${currentLanguage}${authorLink}`)
      : navigate(`${authorLink}`);
  };

  const socialMediaInProperOrder =
    properOrderForSocialMedia(author.contact) || [];
  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          {author.photoUrl && (
            <div className={styles.imageWrapper} onClick={navigateToAuthor}>
              <img
                src={author.photoUrl}
                alt="Author"
                className={styles.authorPhoto}
              />
            </div>
          )}
          <div className={styles.authorInfo}>
            <div className={styles.infoWrapper}>
              <div>
                <InternalLink
                  className={styles.name}
                  url={authorUrl(author.slug)}
                >
                  {author.firstName} {author.lastName}
                </InternalLink>
                <div
                  className={`${styles.position} ${withSocialLinks &&
                    styles.noUnderline}`}
                >
                  {author.position}
                </div>
              </div>
              {withSocialLinks && (
                <div className={styles.scoialLinks}>
                  {socialMediaInProperOrder.map(item => (
                    <ExternalLink blank={true} key={item.icon} url={item.url}>
                      <SocialMediaIcon
                        icon={item.icon}
                        className={styles.icon}
                      />
                    </ExternalLink>
                  ))}
                </div>
              )}
            </div>
            {author.biography && (
              <div
                className={styles.biography}
                dangerouslySetInnerHTML={{ __html: author.biography }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
