import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';

import { Models } from './index';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';
import { IBlogPopularArticleProps } from '../components/blog/blog-article/blog-popular-articles/blog-popular-articles';

export class BlogPopularArticleModel extends ContentItem {
  get id(): string {
    return get(this._config, 'id', '');
  }

  get slug(): string {
    return get(this._config, 'elements.basic_page_settings__url.value', '');
  }

  get title(): string {
    return get(this._config, 'elements.title_value.value', '');
  }

  get publicationDate(): string {
    return get(this._config, 'elements.publication_date.value', '');
  }

  get image(): {
    url: string;
    description: string;
  } {
    return get(this._config, 'elements.image.value[0]', '');
  }

  get categoryUrl(): string {
    return get(
      this._config,
      'elements.blog_category.value[0].elements.category_url_value.value',
      ''
    );
  }

  get categoryName(): string {
    return get(
      this._config,
      'elements.blog_category.value[0].elements.category_name.value',
      ''
    );
  }

  get author(): IEmployeeBoxProps {
    const authorData = get(this._config, 'elements.author.value[0]', {});
    const modeledData = Models.EmployeeBoxModel.create(
      authorData as IEmployeeBox
    );

    return modeledData;
  }

  public static create(data: IBlogPopularArticle): IBlogPopularArticleProps {
    const {
      slug,
      title,
      publicationDate,
      image,
      categoryName,
      categoryUrl,
      id
    } = new BlogPopularArticleModel(data);

    return {
      id,
      slug,
      title,
      publicationDate,
      image,
      categoryName,
      categoryUrl
    };
  }
}
