import { IBlogPopularArticlesProps } from '../components/blog/blog-article/blog-popular-articles/blog-popular-articles';
import { BlogPopularArticleModel } from '../models/blog-popular-article-model';
import { filterById } from '../utils/utils';

export const popularArticlesDataResolver = (
  popularFromIds: {
    nodes: IBlogPopularArticle[];
  },
  popularFromCategory: {
    nodes: IBlogPopularArticle[];
  }
): IBlogPopularArticlesProps[] => {
  let popularArticles = [];
  if (popularFromIds.nodes.length < 3) {
    const filteredFromCategory = filterById(popularFromCategory.nodes);
    popularArticles = [...popularFromIds.nodes, ...filteredFromCategory].slice(
      0,
      3
    );

    return popularArticles.map(article =>
      BlogPopularArticleModel.create(article)
    );
  }
  popularArticles = [...popularFromIds.nodes];
  const filteredArticles = filterById(popularArticles);
  return filteredArticles.map(article =>
    BlogPopularArticleModel.create(article)
  );
};
