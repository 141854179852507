import format from 'date-fns/format';
import React, { FunctionComponent, useMemo } from 'react';
import { getLocaleByCode } from '../../../../../utils/locale';
import { blogArticleUrl } from '../../../../../utils/utils';
import { InternalLink } from '../../../../internal-link/internal-link';
import styles from './blog-popular-article-item.module.scss';

export interface IBlogPopularArticleItemProps {
  language: string;
  id: string;
  title: string;
  slug: string;
  publicationDate: string;
  categoryUrl: string;
  categoryName: string;
  image: {
    url: string;
    description: string;
  };
}

const BlogPopularArticleItemComponent: FunctionComponent<IBlogPopularArticleItemProps> = props => {
  const {
    language,
    slug,
    title,
    publicationDate,
    categoryUrl,
    image: { url, description: alt }
  } = props;

  const articleUrl = blogArticleUrl(slug, categoryUrl);
  const locale = useMemo(() => getLocaleByCode(language), [language]);
  const dateToDisplay = format(new Date(publicationDate), 'dd MMMM yyyy', {
    locale
  });

  return (
    <InternalLink className={styles.articleWrapper} url={articleUrl}>
      <div className={styles.imageWrapper}>
        <img className={styles.articleImage} src={url} alt={alt} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.date}>{dateToDisplay}</div>
    </InternalLink>
  );
};

export const BlogPopularArticleItem = React.memo(
  BlogPopularArticleItemComponent
);
